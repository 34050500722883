import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is a White Label NFT Marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">White label NFT Marketplace is a pre-designed solution for startups to launch a customized NFT Marketplace where users can perform NFT-based trading operations. The term- white label means a readymade product without a brand name and you can alter it to your needs. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How much does White Label NFT Marketplace cost?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost of a White label and customized NFT Marketplace ranges from $ 15,000 to $18,000 on average. However, this cost can vary depending on your requirements and the complexities involved in the NFT platform development. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What are some White Label NFT Marketplace solutions?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The best white label customized NFT Marketplace solutions in the industry are the clones of popular existing NFT Marketplaces. Opensea NFT Marketplace clone, Rarible NFT Marketplace clone, and Enjin NFT Marketplace have increasingly attracted users since they have built a strong brand already. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How to build the best White Label NFT Marketplace?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">In order to build the best white label customized NFT Marketplace, it is important to approach a top NFT Marketplace development company like Coinsclone. We have abundant experience in providing top-notch white-label solutions to entrepreneurs and our experts will guide you to success in the shortest time. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How much time does it take to deploy a White Label NFT Marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Since our white label customized NFT Marketplace is a readily available solution, it can be deployed in just seven to ten days. However, if you require complex and additional customizations, the time taken could slightly vary. 
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection