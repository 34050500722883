import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc mb-0 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>Coinsclone for</span>
            Developing White Label NFT Marketplace?</h3>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/complete-solution.svg" alt="Complete solution Image1" />
                </div>
                <h3>
                  Complete solution 
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                You get everything to deploy your NFT Marketplace under a single roof. No need to work with multiple vendors.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/integrated-tokens.svg" alt="Integrated tokens Image1" />
                </div>
                <h3>
                Integrated tokens
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Tokens can be automatically generated using the inbuilt features of our NFT Marketplace or we can build customized tokens for your unique needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/multi-asset.svg" alt="Multi-asset Image1" />
                </div>
                <h3>
                Multi-asset
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                You can create NFTs for multiple assets using our customized NFT Marketplace ranging from digital art to music to real estate.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/multi-transaction-motes.svg" alt="Multiple opportunities for users Image1" />
                </div>
                <h3>
                Multiple opportunities for users
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Once we have deployed your NFT Marketplace, your users can sell, buy tokens, trade them, and even organize auctions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/no-surprises.svg" alt="On-time delivery Image1" />
                </div>
                <h3>
                On-time delivery
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                We stick to schedules and budgets on most occasions. Unless something drastic happens, our budget and time estimates never change with the project.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/originality.svg" alt="Experienced developers team Image1" />
                </div>
                <h3>
                Experienced developers team
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Our expert developer team has vast experience in the blockchain field and we provide instant solutions to any blockchain-related queries.
                </p>
              </div>
            </div>
          </div>
        </div>


      <div className='dot-box'>
        <p>Build a Robust NFT Marketplace Platform with our skilled Team of Developers.</p>
        <div className='dot-btn'>
        <a href="https://www.coinsclone.com/nft-marketplace-development/" className="bluebtn">Create NFT Marketplace</a>
        <a href="https://www.coinsclone.com/contact-us/" className="bluebtn">Talk to our experts</a>
        </div>
      </div>

      </div>
    </section>  
  )
}

export default WhyChoose